import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { getUserRoleFromToken } from "../../../../utils/auth";
import { useOrders } from "../../../helper/OrderContext";
import { useOrdersShop } from "../../../helper/OrderWebshopContext";
import Icons from "../../icons/ArcviewIconProvider";
import { DashboardIcon, GearIcon, IdCardIcon, ListBulletIcon } from "@radix-ui/react-icons";

type SetSidebarExpanded = React.Dispatch<React.SetStateAction<boolean>>;
interface TopBarProps {
	isSidebarExpanded: boolean;
	setSidebarExpanded: SetSidebarExpanded;
}

const Sidebar: FunctionComponent<TopBarProps> = ({ isSidebarExpanded, setSidebarExpanded }) => {
	const userRole = getUserRoleFromToken();
	const [isSubMenuExpanded, setSubMenuExpanded] = useState(false);
	const [isSubMenuExpandedShop, setSubMenuExpandedShop] = useState(false);
	const [isSubMenuExpandedAdmin, setSubMenuExpandedAdmin] = useState(false);
	const [isSubMenuExpandedCustomAdmin, setSubMenuExpandedCustomAdmin] = useState(false);
	const { ordersPickUp, ordersPick, ordersPack, ordersReturn } = useOrders();
	const { ordersPickShop, ordersPackShop, ordersPickUpShop, ordersReturnShop, ordersHistoryShop } = useOrdersShop();
	useEffect(() => {
		const savedSidebarState = localStorage.getItem("isSidebarExpanded");
		if (savedSidebarState) {
			setSidebarExpanded(JSON.parse(savedSidebarState));
		}

		// Zustand des Untermenüs aus dem lokalen Speicher lesen
		const savedSubMenuState = localStorage.getItem("isSubMenuExpanded");
		if (savedSubMenuState) {
			setSubMenuExpanded(JSON.parse(savedSubMenuState));
		}
		const savedSubMenuStateShop = localStorage.getItem("isSubMenuExpandedShop");
		const savedSubMenuStateAdmin = localStorage.getItem("isSubMenuExpandedAdmin");
		const savedSubMenuStateAdminManufacturer = localStorage.getItem("isSubMenuExpandedAdminManufacturer");
		const savedSubMenuStateCustomAdmin = localStorage.getItem("isSubMenuExpandedCustomAdmin");
		if (savedSubMenuStateShop) {
			setSubMenuExpandedShop(JSON.parse(savedSubMenuStateShop));
		}
		if (savedSubMenuStateAdmin) {
			setSubMenuExpandedAdmin(JSON.parse(savedSubMenuStateAdmin));
		}
	}, []);

	const toggleSidebar = () => {
		setSidebarExpanded(!isSidebarExpanded);
		localStorage.setItem("isSidebarExpanded", JSON.stringify(!isSidebarExpanded));
	};

	const toggleSubMenu = () => {
		setSubMenuExpanded(!isSubMenuExpanded);
		localStorage.setItem("isSubMenuExpanded", JSON.stringify(!isSubMenuExpanded));
	};

	const toggleSubMenuShop = () => {
		setSubMenuExpandedShop(!isSubMenuExpandedShop);
		localStorage.setItem("isSubMenuExpandedShop", JSON.stringify(!isSubMenuExpandedShop));
		console.log("PICKEN-LENGTH EXPANDED " + ordersPick.length);
	};

	const toggleSubMenuAdmin = () => {
		setSubMenuExpandedAdmin(!isSubMenuExpandedAdmin);
		localStorage.setItem("isSubMenuExpandedAdmin", JSON.stringify(!isSubMenuExpandedAdmin));
	};

	const toggleSubMenuCustomAdmin = () => {
		setSubMenuExpandedCustomAdmin(!isSubMenuExpandedCustomAdmin);
		localStorage.setItem("isSubMenuExpandedCustomAdmin", JSON.stringify(!isSubMenuExpandedCustomAdmin));
	};

	return (
		<div
			className={`w-auto ${
				isSidebarExpanded ? "max-w-xs" : "max-w-0"
			} h-screen bg-sidebarGrayLight-700 dark:bg-sidebarGray-800 transition-width duration-300`}
		>
			{isSidebarExpanded ? (
				<div className="h-full flex flex-col justify-start">
					<div className="bg-sidebarGrayLight-800 dark:bg-sidebarGray-900 h-[66px] flex items-center justify-start">
						<div className="flex items-center justify-between w-full">
							<NavLink to={"/"} className="p-1.5 rounded flex items-end justify-end gap-2">
								<span className="dark:hidden">
									<Icons.ArcViewAltDark />
								</span>
								<span className="hidden dark:flex">
									<Icons.ArcViewAlt />
								</span>
							</NavLink>
							<button
								className="z-[20] mr-2 flex items-center justify-center w-8 h-8 dark:text-white rounded bg-sidebarGrayLight-500 text-sidebarGrayLight-900 dark:bg-sidebarGray-800"
								onClick={toggleSidebar}
							>
								<svg
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.85355 3.85355C7.04882 3.65829 7.04882 3.34171 6.85355 3.14645C6.65829 2.95118 6.34171 2.95118 6.14645 3.14645L2.14645 7.14645C1.95118 7.34171 1.95118 7.65829 2.14645 7.85355L6.14645 11.8536C6.34171 12.0488 6.65829 12.0488 6.85355 11.8536C7.04882 11.6583 7.04882 11.3417 6.85355 11.1464L3.20711 7.5L6.85355 3.85355ZM12.8536 3.85355C13.0488 3.65829 13.0488 3.34171 12.8536 3.14645C12.6583 2.95118 12.3417 2.95118 12.1464 3.14645L8.14645 7.14645C7.95118 7.34171 7.95118 7.65829 8.14645 7.85355L12.1464 11.8536C12.3417 12.0488 12.6583 12.0488 12.8536 11.8536C13.0488 11.6583 13.0488 11.3417 12.8536 11.1464L9.20711 7.5L12.8536 3.85355Z"
										fill="currentColor"
										fillRule="evenodd"
										clipRule="evenodd"
									></path>
								</svg>
							</button>
						</div>
					</div>
					<div className="relative p-0 m-0 min-w-0 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-sidebarGrayLight-500 scrollbar-track-sidebarGray-800 w-max lg:min-w-[300px] custom-scrollbar">
						{userRole === "bucher" && (
							<>
								<div className="px-4 pt-4">
									<span className="text-gray-900 dark:text-sidebarGray-400 text-xs">TOOLS</span>
									<hr className="border-sidebarGray-700" />
								</div>
								<ul className="flex flex-col space-y-2 ml-4 mt-2">
									<li>
										<NavLink
											to="/"
											className={({ isActive }) =>
												`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
													isActive
														? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
														: ""
												}`
											}
										>
											<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
												<div className="flex items-center justify-center gap-2">
													<DashboardIcon />
													<span className="text-xs">Dashboard</span>
												</div>
											</div>
										</NavLink>
									</li>
								</ul>
							</>
						)}
						<div className="px-4 pt-4">
							<span className="text-gray-900 dark:text-sidebarGray-400 text-xs">KANÄLE</span>
							<hr className="border-sidebarGray-700" />
						</div>
						<div className="px-2 pt-4 ">
							<ul className="flex flex-col space-y-2">
								{userRole === "fleischmann" && (
									<li>
										<div
											className="relative text-white flex justify-between cursor-pointer"
											onClick={toggleSubMenuCustomAdmin}
										>
											<div className="flex items-center w-full">
												<div className="absolute inset-y-0 left-0 flex items-center pl-2">
													<Icons.ShopIcon />
												</div>
												<span className="flex items-center justify-between w-full pl-12 pr-2 py-2 hover:bg-sidebarGray-400 dark:hover:bg-sidebarGray-700 rounded cursor-pointer text-gray-900 dark:text-gray-50 text-sm">
													Admin Dashboard
													<button
														className={`right-0 p-1 flex items-center transition-transform ${
															isSubMenuExpandedCustomAdmin ? "rotate-90" : ""
														}`}
													>
														<svg
															width="15"
															height="15"
															viewBox="0 0 15 15"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
																fill="currentColor"
																fillRule="evenodd"
																clipRule="evenodd"
															></path>
														</svg>
													</button>
												</span>
											</div>
										</div>
										<div
											className={`${
												isSubMenuExpandedCustomAdmin ? "block" : "hidden"
											} transition-all duration-300`}
										>
											<div className="pt-2 pl-[18px]">
												<ul className="flex flex-col text-white border-l border-sidebarGray-700 gap-1">
													<li>
														<NavLink
															to="admin/custom-product-control"
															className={({ isActive }) =>
																`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																	isActive
																		? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
																		: ""
																}`
															}
														>
															<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																<div className="flex items-center justify-center gap-2 ">
																	<ListBulletIcon />
																	<span className="text-xs">Produktübersicht</span>
																</div>
															</div>
														</NavLink>
													</li>
													<li>
														<NavLink
															to="admin/custom-content-control"
															className={({ isActive }) =>
																`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																	isActive
																		? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
																		: ""
																}`
															}
														>
															<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																<div className="flex items-center justify-center gap-2 ">
																	<GearIcon />
																	<span className="text-xs">Content Management</span>
																</div>
															</div>
														</NavLink>
													</li>
												</ul>
											</div>
										</div>
									</li>
								)}
								{userRole === "ksw" && (
									<li>
										<div
											className="relative text-white flex justify-between cursor-pointer"
											onClick={toggleSubMenuAdmin}
										>
											<div className="flex items-center w-full">
												<div className="absolute inset-y-0 left-0 flex items-center pl-2">
													<Icons.ShopIcon />
												</div>
												<span className="flex items-center justify-between w-full pl-12 pr-2 py-2 hover:bg-sidebarGray-400 dark:hover:bg-sidebarGray-700 rounded cursor-pointer text-gray-900 dark:text-gray-50 text-sm">
													Admin Dashboard
													<button
														className={`right-0 p-1 flex items-center transition-transform ${
															isSubMenuExpandedAdmin ? "rotate-90" : ""
														}`}
													>
														<svg
															width="15"
															height="15"
															viewBox="0 0 15 15"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
																fill="currentColor"
																fillRule="evenodd"
																clipRule="evenodd"
															></path>
														</svg>
													</button>
												</span>
											</div>
										</div>
										<div
											className={`${
												isSubMenuExpandedAdmin ? "block" : "hidden"
											} transition-all duration-300`}
										>
											<div className="pt-2 pl-[18px]">
												<ul className="flex flex-col text-white border-l border-sidebarGray-700 gap-1">
													<li>
														<NavLink
															to="admin/product-control"
															className={({ isActive }) =>
																`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																	isActive
																		? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
																		: ""
																}`
															}
														>
															<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																<div className="flex items-center justify-center gap-2 ">
																	<ListBulletIcon />
																	<span className="text-xs">Produktübersicht</span>
																</div>
															</div>
														</NavLink>
													</li>
												</ul>
											</div>
										</div>
									</li>
								)}
								{userRole === "bucher" && (
									<li className="">
										<div>
											<div
												className="relative text-white flex justify-between cursor-pointer"
												onClick={toggleSubMenuAdmin}
											>
												<div className="flex items-center w-full">
													<div className="absolute inset-y-0 left-0 flex items-center pl-2">
														<Icons.ProductIcon />
													</div>
													<span className="flex items-center justify-between w-full pl-12 pr-2 py-2 hover:bg-sidebarGray-400 dark:hover:bg-sidebarGray-700 rounded cursor-pointer text-gray-900 dark:text-gray-50 text-sm">
														Produktübersicht
														<button
															className={`right-0 p-1 flex items-center transition-transform ${
																isSubMenuExpandedAdmin ? "rotate-90" : ""
															}`}
														>
															<svg
																width="15"
																height="15"
																viewBox="0 0 15 15"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
																	fill="currentColor"
																	fillRule="evenodd"
																	clipRule="evenodd"
																></path>
															</svg>
														</button>
													</span>
												</div>
											</div>
											<div
												className={`${
													isSubMenuExpandedAdmin ? "block" : "hidden"
												} transition-all duration-300`}
											>
												<div className="pt-2 pl-[18px]">
													<ul className="flex flex-col text-white border-l border-sidebarGray-700 gap-1">
														<li>
															<NavLink
																to="admin/product"
																end
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M3.30902 1C2.93025 1 2.58398 1.214 2.41459 1.55279L1.05279 4.27639C1.01807 4.34582 1 4.42238 1 4.5V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V4.5C14 4.42238 13.9819 4.34582 13.9472 4.27639L12.5854 1.55281C12.416 1.21403 12.0698 1.00003 11.691 1.00003L7.5 1.00001L3.30902 1ZM3.30902 2L7 2.00001V4H2.30902L3.30902 2ZM8 4V2.00002L11.691 2.00003L12.691 4H8ZM7.5 5H13V13H2V5H7.5ZM5.5 7C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H5.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">
																			Produktübersicht
																		</span>
																	</div>
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="admin/product/manufacturer"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">
																			Herstellerinformationen
																		</span>
																	</div>
																</div>
															</NavLink>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div></div>
									</li>
								)}
								{userRole === "bucher" && (
									<li className="">
										<div>
											<div
												className="relative text-white flex justify-between cursor-pointer"
												onClick={toggleSubMenuShop}
											>
												<div className="flex items-center w-full">
													<div className="absolute inset-y-0 left-0 flex items-center pl-2">
														<Icons.ShopIcon />
													</div>
													<span className="flex items-center justify-between w-full pl-12 pr-2 py-2 hover:bg-sidebarGray-400 dark:hover:bg-sidebarGray-700 rounded cursor-pointer text-gray-900 dark:text-gray-50 text-sm">
														Webshop
														<button
															className={`right-0 p-1 flex items-center transition-transform ${
																isSubMenuExpandedShop ? "rotate-90" : ""
															}`}
														>
															<svg
																width="15"
																height="15"
																viewBox="0 0 15 15"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
																	fill="currentColor"
																	fillRule="evenodd"
																	clipRule="evenodd"
																></path>
															</svg>
														</button>
													</span>
												</div>
											</div>
											<div
												className={`${
													isSubMenuExpandedShop ? "block" : "hidden"
												} transition-all duration-300`}
											>
												<div className="pt-2 pl-[18px]">
													<ul className="flex flex-col text-white border-l border-sidebarGray-700 gap-1">
														<li>
															<NavLink
																to="webshop/eingang"
																className={({ isActive }) =>
																	`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2 ">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H12V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H12C12.5523 14 13 13.5523 13 13V2C13 1.44772 12.5523 1 12 1H4.5ZM6.60355 4.89645C6.40829 4.70118 6.09171 4.70118 5.89645 4.89645C5.70118 5.09171 5.70118 5.40829 5.89645 5.60355L7.29289 7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H7.29289L5.89645 9.39645C5.70118 9.59171 5.70118 9.90829 5.89645 10.1036C6.09171 10.2988 6.40829 10.2988 6.60355 10.1036L8.85355 7.85355C9.04882 7.65829 9.04882 7.34171 8.85355 7.14645L6.60355 4.89645Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">Bestelleingang</span>
																	</div>
																	{ordersPickShop.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPickShop.length > 9
																				? "9+"
																				: ordersPickShop.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/webshop/gepickt"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 disabled:text-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M3.30902 1C2.93025 1 2.58398 1.214 2.41459 1.55279L1.05279 4.27639C1.01807 4.34582 1 4.42238 1 4.5V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V4.5C14 4.42238 13.9819 4.34582 13.9472 4.27639L12.5854 1.55281C12.416 1.21403 12.0698 1.00003 11.691 1.00003L7.5 1.00001L3.30902 1ZM3.30902 2L7 2.00001V4H2.30902L3.30902 2ZM8 4V2.00002L11.691 2.00003L12.691 4H8ZM7.5 5H13V13H2V5H7.5ZM5.5 7C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H5.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">
																			Versandvorbereitung
																		</span>
																	</div>
																	{ordersPackShop.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPackShop.length > 9
																				? "9+"
																				: ordersPackShop.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/webshop/verpackt"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 disabled:text-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				fillRule="evenodd"
																				clipRule="evenodd"
																				d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738"
																				fill="currentColor"
																			></path>
																		</svg>
																		<span className="text-xs">Paketübergabe</span>
																	</div>
																	{ordersPickUpShop.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPickUpShop.length > 9
																				? "9+"
																				: ordersPickUpShop.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/webshop/historie"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 disabled:text-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">Bestellhistorie</span>
																	</div>
																</div>
															</NavLink>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div></div>
									</li>
								)}
								{userRole === "bucher" && (
									<li className="">
										<div>
											<div
												className="relative text-white flex justify-between cursor-pointer"
												onClick={toggleSubMenu}
											>
												<div className="flex items-center w-full">
													<div className="absolute inset-y-0 left-0 flex items-center pl-2">
														<Icons.OttoIcon />
													</div>
													<span className="flex items-center justify-between w-full pl-12 pr-2 py-2 hover:bg-sidebarGray-400 dark:hover:bg-sidebarGray-700 rounded cursor-pointer text-gray-900 dark:text-gray-50 text-sm">
														Otto
														<button
															className={`right-0 p-1 flex items-center transition-transform ${
																isSubMenuExpanded ? "rotate-90" : ""
															}`}
														>
															<svg
																width="15"
																height="15"
																viewBox="0 0 15 15"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
																	fill="currentColor"
																	fillRule="evenodd"
																	clipRule="evenodd"
																></path>
															</svg>
														</button>
													</span>
												</div>
											</div>
											<div
												className={`${
													isSubMenuExpanded ? "block" : "hidden"
												} transition-all duration-300`}
											>
												<div className="pt-2 pl-[18px]">
													<ul className="flex flex-col text-white border-l border-sidebarGray-700 gap-1">
														<li>
															<NavLink
																to="/otto/picken"
																className={({ isActive }) =>
																	`relative inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white"
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2 ">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H12V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H12C12.5523 14 13 13.5523 13 13V2C13 1.44772 12.5523 1 12 1H4.5ZM6.60355 4.89645C6.40829 4.70118 6.09171 4.70118 5.89645 4.89645C5.70118 5.09171 5.70118 5.40829 5.89645 5.60355L7.29289 7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H7.29289L5.89645 9.39645C5.70118 9.59171 5.70118 9.90829 5.89645 10.1036C6.09171 10.2988 6.40829 10.2988 6.60355 10.1036L8.85355 7.85355C9.04882 7.65829 9.04882 7.34171 8.85355 7.14645L6.60355 4.89645Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">Bestelleingang</span>
																	</div>
																	{ordersPick.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPick.length > 9
																				? "9+"
																				: ordersPick.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/otto/packen"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M3.30902 1C2.93025 1 2.58398 1.214 2.41459 1.55279L1.05279 4.27639C1.01807 4.34582 1 4.42238 1 4.5V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V4.5C14 4.42238 13.9819 4.34582 13.9472 4.27639L12.5854 1.55281C12.416 1.21403 12.0698 1.00003 11.691 1.00003L7.5 1.00001L3.30902 1ZM3.30902 2L7 2.00001V4H2.30902L3.30902 2ZM8 4V2.00002L11.691 2.00003L12.691 4H8ZM7.5 5H13V13H2V5H7.5ZM5.5 7C5.22386 7 5 7.22386 5 7.5C5 7.77614 5.22386 8 5.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H5.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">
																			Versandvorbereitung
																		</span>
																	</div>
																	{ordersPack.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPack.length > 9
																				? "9+"
																				: ordersPack.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/otto/verpacken"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				fillRule="evenodd"
																				clipRule="evenodd"
																				d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738"
																				fill="currentColor"
																			></path>
																		</svg>
																		<span className="text-xs">Paketübergabe</span>
																	</div>
																	{ordersPickUp.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersPickUp.length > 9
																				? "9+"
																				: ordersPickUp.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/otto/retouren"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M1.90321 7.29677C1.90321 10.341 4.11041 12.4147 6.58893 12.8439C6.87255 12.893 7.06266 13.1627 7.01355 13.4464C6.96444 13.73 6.69471 13.9201 6.41109 13.871C3.49942 13.3668 0.86084 10.9127 0.86084 7.29677C0.860839 5.76009 1.55996 4.55245 2.37639 3.63377C2.96124 2.97568 3.63034 2.44135 4.16846 2.03202L2.53205 2.03202C2.25591 2.03202 2.03205 1.80816 2.03205 1.53202C2.03205 1.25588 2.25591 1.03202 2.53205 1.03202L5.53205 1.03202C5.80819 1.03202 6.03205 1.25588 6.03205 1.53202L6.03205 4.53202C6.03205 4.80816 5.80819 5.03202 5.53205 5.03202C5.25591 5.03202 5.03205 4.80816 5.03205 4.53202L5.03205 2.68645L5.03054 2.68759L5.03045 2.68766L5.03044 2.68767L5.03043 2.68767C4.45896 3.11868 3.76059 3.64538 3.15554 4.3262C2.44102 5.13021 1.90321 6.10154 1.90321 7.29677ZM13.0109 7.70321C13.0109 4.69115 10.8505 2.6296 8.40384 2.17029C8.12093 2.11718 7.93465 1.84479 7.98776 1.56188C8.04087 1.27898 8.31326 1.0927 8.59616 1.14581C11.4704 1.68541 14.0532 4.12605 14.0532 7.70321C14.0532 9.23988 13.3541 10.4475 12.5377 11.3662C11.9528 12.0243 11.2837 12.5586 10.7456 12.968L12.3821 12.968C12.6582 12.968 12.8821 13.1918 12.8821 13.468C12.8821 13.7441 12.6582 13.968 12.3821 13.968L9.38205 13.968C9.10591 13.968 8.88205 13.7441 8.88205 13.468L8.88205 10.468C8.88205 10.1918 9.10591 9.96796 9.38205 9.96796C9.65819 9.96796 9.88205 10.1918 9.88205 10.468L9.88205 12.3135L9.88362 12.3123C10.4551 11.8813 11.1535 11.3546 11.7585 10.6738C12.4731 9.86976 13.0109 8.89844 13.0109 7.70321Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">Neuretouren</span>
																	</div>
																	{ordersReturn.length > 0 && (
																		<div className="relative inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-800 rounded-full">
																			{ordersReturn.length > 9
																				? "9+"
																				: ordersReturn.length}
																		</div>
																	)}
																</div>
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/otto/historie"
																className={({ isActive }) =>
																	`inline-block w-full px-2 py-2 text-base rounded-r-lg hover:bg-sidebarGrayLight-800 dark:hover:bg-sidebarGray-700 ${
																		isActive
																			? "bg-sidebarGrayLight-800 dark:bg-sidebarGray-700 text-white "
																			: ""
																	}`
																}
															>
																<div className="flex items-center justify-between gap-2 text-gray-900 dark:text-gray-50">
																	<div className="flex items-center justify-center gap-2">
																		<svg
																			width="15"
																			height="15"
																			viewBox="0 0 15 15"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
																				fill="currentColor"
																				fillRule="evenodd"
																				clipRule="evenodd"
																			></path>
																		</svg>
																		<span className="text-xs">Bestellhistorie</span>
																	</div>
																</div>
															</NavLink>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div></div>
									</li>
								)}
							</ul>
						</div>
						{/* <div className="px-6 pt-6">
                        <div className="flex items-center justify-between">
                            <span className="text-sidebarGray-400 text-xs">KI-TOOLS</span>
                            <div className="px-2 text-sidebarGray-700 rounded bg-orange-300">Kommt bald!</div>
                        </div>
                        <hr className="border-sidebarGray-700" />
                    </div>
                    <div className="px-6 pt-4">
                        <ul className="flex flex-col space-y-2">
                            <li className="relative text-sidebarGray-500 hover:text-white">

                            </li>
                        </ul>
                    </div> */}
					</div>
					{/* <div className="w-full mb-6">
                    <div className="px-6 pt-8">
                        <span className="text-sidebarGray-400 text-xs">EINSTELLUNGEN</span>
                        <hr className="border-sidebarGray-700" />
                    </div>
                    <div className="px-6 pt-4">
                        <ul>
                            <li className="relative text-sidebarGray-500 hover:text-white">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <Icons.ArcViewIcon />
                                </div>
                                <button className="inline-block w-full pl-8 pr-4 py-2 text-sm hover:bg-sidebarGray-800 rounded">Einstellungen</button>
                            </li>
                        </ul>
                    </div>
                </div> */}
					<div className="flex flex-col items-center justify-center border-sidebarGray-700 border-t-[1px] py-2 mt-auto">
						<div className="flex flex-col items-center justify-center">
							<p className="p-1 text-xs flex items-center justify-center text-sidebarGray-500 dark:text-sidebarGray-400">
								Powered by&nbsp;
								<Link
									to={"https://nodiatech.de/"}
									target="_blank"
									rel="noopeneer"
									className="underline decoration-sidebarGray-400 dark:decoration-sidebarGray-400 flex items-center justify-center text-sidebarGray-900 dark:text-sidebarGrayLight-400"
								>
									<span className="text-xs">NodiaTech</span>
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
											fill="currentColor"
											fillRule="evenodd"
											clipRule="evenodd"
										></path>
									</svg>
								</Link>
							</p>
						</div>
						<ul className="flex items-center justify-center">
							<li className="flex items-center justify-center">
								<Link
									className="text-sidebarGray-900 dark:text-sidebarGrayLight-700 text-xs hover:underline"
									target="_blank"
									rel="noopener"
									to={"https://nodiatech.de/impressum"}
								>
									Impressum
								</Link>
							</li>
							<li className="text-sidebarGray-700 dark:text-sidebarGray-400">
								<svg
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.5 2C7.77614 2 8 2.22386 8 2.5L8 12.5C8 12.7761 7.77614 13 7.5 13C7.22386 13 7 12.7761 7 12.5L7 2.5C7 2.22386 7.22386 2 7.5 2Z"
										fill="currentColor"
										fillRule="evenodd"
										clipRule="evenodd"
									></path>
								</svg>
							</li>
							<li className="flex items-center justify-center">
								<Link
									className="text-sidebarGray-900 dark:text-sidebarGrayLight-700 text-xs hover:underline"
									target="_blank"
									rel="noopener"
									to={"https://nodiatech.de/datenschutz"}
								>
									Datenschutz
								</Link>
							</li>
						</ul>
						<span className="mt-4 pb-1 text-xs text-sidebarGray-400 dark:text-sidebarGray-500">
							Version 2.1
						</span>
					</div>
				</div>
			) : (
				<div className="relative w-full h-full">
					<button
						className="z-[60] absolute left-6 flex items-center justify-center top-[18px] w-[31px] h-[31px] text-sidebarGrayLight-900 dark:text-white rounded bg-sidebarGrayLight-500 dark:bg-sidebarGray-900"
						onClick={toggleSidebar}
					>
						<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M2.14645 11.1464C1.95118 11.3417 1.95118 11.6583 2.14645 11.8536C2.34171 12.0488 2.65829 12.0488 2.85355 11.8536L6.85355 7.85355C7.04882 7.65829 7.04882 7.34171 6.85355 7.14645L2.85355 3.14645C2.65829 2.95118 2.34171 2.95118 2.14645 3.14645C1.95118 3.34171 1.95118 3.65829 2.14645 3.85355L5.79289 7.5L2.14645 11.1464ZM8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L12.8536 7.85355C13.0488 7.65829 13.0488 7.34171 12.8536 7.14645L8.85355 3.14645C8.65829 2.95118 8.34171 2.95118 8.14645 3.14645C7.95118 3.34171 7.95118 3.65829 8.14645 3.85355L11.7929 7.5L8.14645 11.1464Z"
								fill="currentColor"
								fillRule="evenodd"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
};

export default Sidebar;
